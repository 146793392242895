/* Reset */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
input, textarea, select, button, a, img {
	outline: none !important;
	resize: none;
	border: 0;
	border-color: transparent;
	outline-style: none;
	-moz-outline-style: none;
}
strong { font-weight: 700; }

/* Structure */
*, *:after, *:before {
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-ms-box-sizing: border-box;
	-o-box-sizing: border-box;
}
.row {
	display: block;
}
.row:after {
	content: "";
	clear: both;
	display: table;
}
[class*="col-"] {
	display: block;
	float: left;
}
.col-1 { width: 8.3333%; }
.col-2 { width: 16.6666%; }
.col-3 { width: 25%; }
.col-4 { width: 33.3333%; }
.col-5 { width: 41.6666%; }
.col-6 { width: 50%; }
.col-7 { width: 58.3333%; }
.col-8 { width: 66.6666%; }
.col-9 { width: 75%; }
.col-10 { width: 83.3333%; }
.col-11 { width: 91.6666%; }
.col-12 { width: 100%; }
.anim1 {
	transition: all 0.3s;
	-moz-transition: all 0.3s;
	-webkit-transition: all 0.3s;
	-ms-transition: all 0.3s;
	-o-transition: all 0.3s;
}
.anim2 {
	transition: all 0.15s;
	-moz-transition: all 0.15s;
	-webkit-transition: all 0.15s;
	-ms-transition: all 0.15s;
	-o-transition: all 0.15s;
}
.al { text-align: left; }
.ar { text-align: right; }
.ac { text-align: center; }
div.lightbox {
	width: 100%;
	height: 100%;
	position: fixed;
	background-color: rgba(0, 0, 0, 0.85);
	color: #FFFFFF;
	z-index: 999;
	align-items: center;
	justify-content: center;
	overflow: auto;
}
div.lightbox > span.close {
	position: fixed;
	z-index: 999;
	top: 50px;
	right: 50px;
	cursor: pointer;
	font-size: 30px;
}
div.lightbox > p {
	display: block;
	width: 90%;
	padding: 50px;
	font-size: 16px;
	line-height: 26px;
	font-weight: 400;
	text-align: justify;
}

/* Custom */
body {
    line-height: 1;
    /* background-color: #FAFAFA; */
	background-color: #FFFFFF;
    font-family: 'Asap', sans-serif;
    font-weight: 400;
    color: #555555;
}
header.mainHeader {
	width: 100%;
	height: auto;
	max-height: 100px;
	display: block;
	background-color: #FFFFFF;
	/* box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.15);
	-moz-box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.15);
	-webkit-box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.15);
	-ms-box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.15);
	-o-box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.15); */
	padding: 10px 20px;
	position: relative;
	z-index: 99;
}
header.mainHeader:after {
	content: "";
	width: 0; 
	height: 0; 
	border-left: 0px solid transparent;
	border-right: 100vw solid transparent;
	border-top: 60px solid #FFFFFF;
	bottom: -70px;
	right: 0;
	position: absolute;
}
header.mainHeader #mainLogo, header.mainHeader #mainLogoTicket {
	max-width: 100%;
	width: auto;
	max-height: 130px;
	/* margin-bottom: 20px; */
	display: inline-block;
	vertical-align: middle;
	/* margin-top: 25px; */
	position: relative;
	z-index: 99;
}
header.mainHeader #mainLogoTicket { margin-left: 30px; }
header.mainHeader #slogan {
	position: absolute;
	max-width: 100px;
	top: 50%;
	transform: translate(0, -50%);
	-moz-transform: translate(0, -50%);
	-webkit-transform: translate(0, -50%);
	-ms-transform: translate(0, -50%);
	-o-transform: translate(0, -50%);
	margin-left: 15px;
}
header.mainHeader h6 {
	display: inline-block;
	vertical-align: middle;
	font-weight: 400;
	color: #BBBBBB;
	font-size: 22px;
	margin-left: 35px;
	border-left: 1px solid #EEEEEE;
	padding: 20px 0 20px 20px;
}
header.mainHeader #mainMenu {
	width: auto;
	height: auto;
	padding-top: 35px;
}
header.mainHeader #mainMenu > li {
	display: inline-block;
	padding-left: 25px;
}
header.mainHeader #mainMenu > li > a {
	display: block;
	text-decoration: none;
	font-weight: 700;
	color: #BBBBBB;
	font-size: 22px;
}
header.mainHeader #mainMenu > li > a:hover, header.mainHeader #mainMenu > li > a.active { color: #777777; }
header.mainHeader #mobileMenuActivator {
	font-size: 30px;
	padding-top: 16px;
	color: #BBBBBB;
	cursor: pointer;
	display: none;
}
header.mainHeader #mobileMenu {
	width: 100%;
	height: auto;
	max-height: 0;
	text-align: center;
	position: absolute;
	left: 0;
	background-color: #FFFFFF;
	top: 85px;
	overflow: hidden;
}
header.mainHeader #mobileMenu.visible { max-height: 1000px; }
header.mainHeader #mobileMenu > li {
	display: block;
	padding: 15px;
}
header.mainHeader #mobileMenu > li:last-of-type { padding-bottom: 30px; }
header.mainHeader #mobileMenu > li > a {
	display: block;
	text-decoration: none;
	font-weight: 700;
	color: #BBBBBB;
	font-size: 18px;
}
header.mainHeader #mobileMenu > li > a.active { color: #777777; }
#fullContainer {
	width: 100%;
	height: calc(100vh - 100px);
	display: flex;
	align-items: center;
	justify-content: center;
	background-position: 50% 50%;
	background-repeat: no-repeat;
	background-size: cover;
	border-bottom: 10px solid #CCCCCC;
	overflow: hidden;
	position: relative;
	-webkit-transform: skewy(-2deg);
  	transform: skewy(-2deg);
}
/* #fullContainer:after {
	content: "";
	width: 0; 
	height: 0; 
	border-left: 100vw solid transparent;
	border-right: 0px solid transparent;
	border-bottom: 75px solid #CCCCCC;
	bottom: 0px;
	left: 0;
	position: absolute;
} */
#fullContainer > div.text {
	overflow: scroll;
	width: 80%;
	height: 70%;
	background-color: rgba(255, 255, 255, 0.7);
	border-radius: 5px;
	-moz-border-radius: 5px;
	-webkit-border-radius: 5px;
	-ms-border-radius: 5px;
	-o-border-radius: 5px;
	padding: 100px;
	-webkit-transform: skewy(2deg);
  	transform: skewy(2deg);
}
#fullContainer > div.text > p {
	font-size: 18px;
	line-height: 26px;
	color: #333333;
	font-weight: 400;
	text-align: justify;
}
#fullContainer > div.text > p a { color: #10A0D2; }
#fullContainer > div.text > ul { padding: 10px 20px; }
#fullContainer > div.text > ul > li {
	font-size: 18px;
	line-height: 26px;
	color: #333333;
	list-style: initial;
	margin-left: 20px;
}
#process {
	width: 100%;
	height: calc(100vh - 100px);
	min-height: 600px;
	display: flex;
	align-items: center;
	justify-content: center;
	background-position: 50% 50%;
	background-repeat: no-repeat;
	background-size: cover;
	background-image: url(./assets/bg-process-1.jpg);
	border-bottom: 10px solid #CCCCCC;
	overflow: hidden;
	position: relative;
	z-index: 98;
	-webkit-transform: skewy(-2deg);
  	transform: skewy(-2deg);
}
/* #process:after {
	content: "";
	width: 0; 
	height: 0; 
	border-left: 100vw solid transparent;
	border-right: 0px solid transparent;
	border-bottom: 75px solid #CCCCCC;
	bottom: 0px;
	left: 0;
	position: absolute;
} */
section#wasMacht { margin-top: -27px; }
#process > div.step {
	width: 0;
	height: auto;
	visibility: hidden;
	opacity: 0;
	display: flex;
	flex-flow: column;
	align-items: center;
	max-width: 1000px;
	margin: 0;
	padding: 0;
	-webkit-transform: skewy(2deg);
  	transform: skewy(2deg);
}
#process > div.step.visible {
	visibility: visible;
	opacity: 1;
	width: 100%;
}
#process > div.step > h1 {
	font-weight: 700;
	letter-spacing: 2px;
	font-size: 45px;
	color: #FFFFFF;
}
#process > div.step > h2 {
	font-weight: 500;
	letter-spacing: 1px;
	font-size: 35px;
	line-height: 42px;
	color: #FFFFFF;
	text-align: center;
}
#process > div.step > h3 {
	font-weight: 500;
	letter-spacing: 1px;
	font-size: 22px;
	line-height: 29px;
	color: #FFFFFF;
	text-align: center;
	padding-top: 16px;
}
#process > div.step > p {
	font-weight: 400;
	font-size: 20px;
	padding-top: 20px;
	color: #DDDDDD;
}
#process > div.step button.cta1 {
	background-color: #10A0D2;
	color: #FFFFFF;
	cursor: pointer;
	display: block;
	margin: 0 auto;
	font-size: 18px;
	padding: 18px 50px;
	margin-top: 25px;
	border-radius: 5px;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    font-family: 'Asap', sans-serif;
    font-weight: 400;
}
#process > div.step button#ctaHome {
	background-color: #C0392B;
	color: #FFFFFF;
	cursor: pointer;
	display: block;
	margin: 0 auto;
	font-size: 18px;
	padding: 18px 50px;
	margin-top: 25px;
	border-radius: 5px;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    font-family: 'Asap', sans-serif;
    font-weight: 400;
}
#process > div.step button.cta1:hover { background-color: #37AFD7; }
#process > div.step button#ctaHome:hover { background-color: #A53125; }
#process > div.step button.cta1.disabled {
	cursor: not-allowed;
	background-color: #EEEEEE;
	color: #CCCCCC;
}
#process > div.step button.cta1 > span {
	display: none;
	margin-left: 5px;
	vertical-align: middle;
}
#process > div.step button.cta2 {
	color: #FFFFFF;
	cursor: pointer;
	display: block;
	margin: 0 auto;
	font-size: 14px;
	margin-top: 15px;
	text-decoration: underline;
	background-color: transparent;
	font-family: 'Asap', sans-serif;
    font-weight: 400;
}
#process > div.step > div.options {
	display: flex;
	flex-flow: row wrap;
	/* justify-content: space-between; */
}
#process > div.step > div.options > button.option, #process > div.step > div.options > a.option {
	background-color: #10A0D2;
	color: #FFFFFF;
	cursor: pointer;
	display: inline-block;
	margin: 0 auto;
	font-size: 18px;
	padding: 15px;
	width: 160px;
	max-height: 130px;
	min-height: 130px;
	vertical-align: top;
	margin: 25px 10px 0;
	border-radius: 5px;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    font-family: 'Asap', sans-serif;
    font-weight: 400;
}
#process > div.step > div.options > a.option {
	display: flex;
	justify-content: center;
	align-items: center;
	text-decoration: none;
	text-align: center;
	padding: 0 15px;
	line-height: 24px;
}
#process > div.step > div.options > button.option.selected {
	background-color: #FFFFFF;
	color: #10A0D2;
}
#process > div.step > div.options > button.option.selected > span { color: #10A0D2; }
#process > div.step > div.options > button.option > span {
	display: block;
	margin-bottom: 10px;
	font-size: 30px;
	color: #FFFFFF;
}
#process > div.step > div.options > button.option:not(.selected):hover { background-color: #37AFD7; }
#process > div.step p.response {
	color: #C0392B;
	margin-top: 25px;
	padding: 25px;
	font-size: 16px;
	line-height: 26px;
	font-weight: 700;
	background-color: rgba(255, 255, 255, 0.7);
	border-radius: 5px;
	-moz-border-radius: 5px;
	-webkit-border-radius: 5px;
	-ms-border-radius: 5px;
	-o-border-radius: 5px;
	text-align: center;
}
#process > div.step p.response2 {
	position: absolute;
	bottom: 10px;
	left: 0;
	width: 100%;
	height: auto;
	padding: 25px;
	background-color: rgba(255, 255, 255, 0.7);
	color: #C0392B;
	font-size: 16px;
	line-height: 26px;
	font-weight: 700;
	text-align: justify;
	z-index: 99;
}
#process > div.step > div.form {
	margin-top: 25px;
	display: block;
	width: 100%;
	text-align: center;
}
#process > div.step > div.form > div.inputCtr {
	display: inline-block;
	width: auto;
	height: auto;
	padding: 10px 15px;
	position: relative;
}
#process > div.step > div.form > div.inputCtr > span {
	color: #FFFFFF;
	font-size: 25px;
	vertical-align: middle;
	margin-top: -3px;
}
#process > div.step > div.form > div.inputCtr input {
	border: 0;
	width: 250px;
	height: 50px;
	margin: 0;
	margin-left: 10px;
	vertical-align: middle;
	border-radius: 5px;
	-moz-border-radius: 5px;
	-webkit-border-radius: 5px;
	-ms-border-radius: 5px;
	-o-border-radius: 5px;
	font-family: 'Asap', sans-serif;
	font-weight: 400;
	color: #333333;
	font-size: 18px;
	padding: 0px 12px;
	border: 3px solid #EEEEEE;
}
#process > div.step > div.form > div.inputCtr input:focus { border: 3px solid #10A0D2; }
#process > div.step > textarea {
	border: 0;
	max-width: 800px;
	width: 100%;
	height: 100px;
	margin: 30px 0 0 0;
	margin-left: 10px;
	vertical-align: middle;
	border-radius: 5px;
	-moz-border-radius: 5px;
	-webkit-border-radius: 5px;
	-ms-border-radius: 5px;
	-o-border-radius: 5px;
	font-family: 'Asap', sans-serif;
	font-weight: 400;
	color: #333333;
	font-size: 18px;
	padding: 8px 12px;
	border: 3px solid #EEEEEE;
}
#process > div.step > textarea:focus { border: 3px solid #10A0D2; }
#process > div.step > div.flightInfo {
	width: 100%;
	padding: 20px;
	background-color: rgba(255, 255, 255, 0.7);
	border-radius: 5px;
	-moz-border-radius: 5px;
	-webkit-border-radius: 5px;
	-ms-border-radius: 5px;
	-o-border-radius: 5px;
	margin-top: 25px;
	color: #666666;
	position: relative;
}
#process > div.step > div.flightInfo > p.airlineFlight {
	font-size: 30px;
	color: #10A0D2;
	font-weight: 400;
	padding-bottom: 25px;
}
#process > div.step > div.flightInfo span.fas {
	color: #10A0D2;
	font-size: 20px;
	margin-bottom: 10px;
}
#process > div.step > div.flightInfo p.airport {
	font-size: 20px;
	font-weight: 700;
	padding-bottom: 10px;
}
#process > div.step > div.flightInfo > div.delayInfo {
	position: absolute;
	background-color: #C0392B;
	padding: 10px 15px;
	color: #FFFFFF;
	font-weight: 400;
	border-radius: 4px;
	-moz-border-radius: 4px;
	-webkit-border-radius: 4px;
	-ms-border-radius: 4px;
	-o-border-radius: 4px;
	right: 20px;
	top: 20px;
}
main { border-bottom: 10px solid #CCCCCC; }
main > section {
	width: 100%;
	display: flex;
}
main > section:last-of-type { padding-bottom: 0; }
main > section > div.info, main > section > div.img { flex: 1; }
main > section > div.info { padding: 70px 35px; }
main > section > div { flex: 1 0 auto; }
main > section > div.info > p {
	font-size: 18px;
	line-height: 28px;
	text-align: justify;
}
main > section > div.info > span {
	font-size: 18px;
	display: block;
	text-align: center;
	margin-bottom: 50px;
	font-size: 70px;
	color: #10A0D2;
}
main > section > div.info > div.tripleList {
	display: inline-block;
	width: 33.3333%;
	margin-top: 30px;
	padding-right: 25px;
	vertical-align: top;
	font-size: 16px;
	line-height: 24px;
}
main > section > div.info > div.block { padding: 20px 0; }
main > section > div.info > div.block > p {
	font-size: 16px;
	line-height: 24px
}
main > section > div.info > div.block > p:first-of-type {
	font-weight: bold;
	font-size: 20px;
	margin-bottom: 10px;
}
main > section > div.info > div.block > p > span {
	width: 45px;
	height: 45px;
	background-color: #10A0D2;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	font-weight: bold;
	border-radius: 50%;
	-moz-border-radius: 50%;
	-webkit-border-radius: 50%;
	-ms-border-radius: 50%;
	-o-border-radius: 50%;
	margin-right: 10px;
	color: #FFFFFF;
	font-size: 20px;
}
main > section > div.img {
	background-size: cover;
	background-position: 50% 50%;
	background-repeat: no-repeat;
	min-width: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
}
main > section > div.img > h2 {
	font-weight: 500;
	font-size: 38px;
	display: block;
	color: #FFFFFF;
	position: relative;
	border-bottom: 4px solid #D83A31;
	padding-bottom: 20px;
}
div#info { padding: 50px; }
div#info div.info, div#info a.info {
	text-align: center;
	padding: 0 40px;
	cursor: default;
	color: inherit;
	text-decoration: none;
}
div#info div.info.hover > span, div#info a.info.hover > span { color: #C0392B; }
div#info div.info > span, div#info a.info > span {
	font-size: 40px;
	display: block;
	margin-bottom: 20px;
	color: #10A0D2;
}
div#info div.info > h3, div#info a.info > h3 {
	font-size: 22px;
	font-weight: 700;
	padding-bottom: 25px;
	font-family: 'Asap', sans-serif;
}
div#info div.info > p, div#info a.info > p {
	font-size: 17px;
	line-height: 27px;
	text-align: justify;
}
div.step > div.info {
	text-align: center;
	padding-top: 20px;
}
div.step > div.info > h3 {
	font-weight: 500;
	letter-spacing: 1px;
	font-size: 22px;
	line-height: 29px;
	color: #FFFFFF;
	text-align: center;
	padding-bottom: 10px;
}
div.step > div.info > p {
	padding-bottom: 10px;
	color: #FFFFFF;
	font-size: 16px;
	line-height: 22px;
}
div.step > div.info > p > a { color: inherit; }
div.tabber {
	width: 75%;
	background-color: #EEEEEE;
	border-radius: 5px;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    margin-top: 30px;
}
div.tabber > ul.tabberNav {
	width: 100%;
	text-align: center;
}
div.tabber > ul.tabberNav > li {
	cursor: pointer;
	text-align: center;
	display: inline-block;
	width: 16.6666666667%;
	height: auto;
	padding: 15px 0;
	background-color: #DDDDDD;
	color: #AAAAAA;
}
div.tabber > ul.tabberNav > li:first-of-type {
	border-top-left-radius: 5px;
	-moz-border-top-left-radius: 5px;
	-webkit-border-top-left-radius: 5px;
	-ms-border-top-left-radius: 5px;
	-o-border-top-left-radius: 5px;
}
div.tabber > ul.tabberNav > li:last-of-type {
	border-top-right-radius: 5px;
	-moz-border-top-right-radius: 5px;
	-webkit-border-top-right-radius: 5px;
	-ms-border-top-right-radius: 5px;
	-o-border-top-right-radius: 5px;
}
div.tabber > ul.tabberNav > li.active, div.tabber > ul.tabberNav > li:hover {
	background-color: #EEEEEE;
	color: #10A0D2;
}
div.tabber > ul.tabberNav > li > span { margin-right: 7px; }
div.tabber > div.tabberContent { padding: 20px; }
div.tabber > div.tabberContent > div > span {
	display: inline-block;
	font-size: 14px;
	vertical-align: middle;
	color: #BBBBBB;
}
div.tabber > div.tabberContent > div > div.inputFileCtr {
	display: inline-block;
	position: relative;
	width: 32.3%;
	margin-right: 1.444444%;
}
div.tabber > div.tabberContent > div > div.inputFileCtr > span {
	position: absolute;
	font-size: 14px;
	left: 10px;
	top: 8px;
}
div.tabber > div.tabberContent > div > div.inputRadioCtr {
	display: inline-block;
	position: relative;
	width: auto;
	margin-right: 20px;
	padding: 5px 0 10px;
}
div.tabber > div.tabberContent > div > div.inputRadioCtr > input {
	width: auto;
	display: inline-block;
	vertical-align: middle;
}
div.tabber > div.tabberContent > div input, div.tabber > div.tabberContent > div label, div.tabber > div.tabberContent > div textarea, div.tabber > div.tabberContent > div select {
	width: 100%;
	display: block;
	font-family: 'Asap', sans-serif;
    font-weight: 400;
    color: #666666;
    font-size: 14px;
    padding: 8px 12px;
    border-radius: 5px;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    margin-bottom: 10px;
    background-color: #FFFFFF;
}
div.tabber > div.tabberContent > div select { cursor: pointer; }
div.tabber > div.tabberContent > div textarea { height: 100px; }
div.tabber > div.tabberContent > div input.half, div.tabber > div.tabberContent > div select.half {
	width: 49.3%;
	display: inline-block;
}
div.tabber > div.tabberContent > div input.third, div.tabber > div.tabberContent > div select.third {
	width: 32.3%;
	margin-right: 1.444444%;
	display: inline-block;
}
div.tabber > div.tabberContent > div > div.inputFileCtr > label {
	display: block;
	padding-left: 30px;
	cursor: pointer;
}
div.tabber > div.tabberContent > div button {
	background-color: #10A0D2;
	color: #FFFFFF;
	cursor: pointer;
	display: block;
	margin: 0;
	font-size: 14px;
	padding: 9px 12px;
	border-radius: 5px;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    float: right;
    font-family: 'Asap', sans-serif;
    font-weight: 400;
}
div.tabber > div.tabberContent > div button:hover { background-color: #37AFD7; }
div.airlinesList {
	position: absolute;
	background-color: #FFFFFF;
	width: 250px;
	margin-left: 31px;
	margin-top: -4px;
	border: 3px solid #10A0D2;
	border-radius: 5px;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    overflow: auto;
    max-height: 350px;
	z-index: 99;
}
div.airlinesList > div.airline {
	width: 100%;
	padding: 15px;
	cursor: pointer;
	font-size: 14px;
	line-height: 20px;
}
div.datenschutzCheck, div.paymentCheck, div.formAdditionalText {
	padding-top: 15px;
	color: #FFFFFF;
	font-size: 12px;
}
div.formAdditionalText > p {
	color: #FFFFFF;
	font-size: 14px;
	line-height: 18px;
	padding: 10px 25px 0;
	text-align: center;
}
div.datenschutzCheck > a, div.paymentCheck > a { color: #FFFFFF; }
div.airlinesList > div.airline:hover { color: #10A0D2; }
button#backButton {
	position: absolute;
	top: 250px;
	left: 20px;
	appearance: none;
	-moz-appearance: none;
	-webkit-appearance: none;
	z-index: 999;
	margin: 0;
	padding: 15px 20px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-flow: row;
	font-family: 'Asap', sans-serif;
    font-weight: 400;
	border-radius: 5px;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
	cursor: pointer;
	background-color: #10A0D2;
	color: #FFFFFF;
	font-size: 16px;
}
button#backButton > span { margin-right: 10px; }
button#backButton:hover { background-color: #37AFD7; }
footer {
	background-color: rgb(238, 238, 238);
	display: block;
	padding: 30px 50px;
	font-weight: 700;
	color: #BBBBBB;
}
footer.withBorder {
	position: relative;
	z-index: 99;
	margin-top: -35px;
	border-top: 10px solid #CCCCCC;
}
footer ul#footerMenu li {
	display: inline-block;
	margin-left: 15px;
}
footer ul#footerMenu > li > a {
	text-decoration: none;
	color: inherit;
	vertical-align: middle;
}
footer ul#footerMenu > li > a:hover { color: #777777; }
footer ul#footerMenu > li > a.hoverBlue:hover { color: #3B5998; }
footer ul#footerMenu > li > a.hoverPurple:hover { color: #D047D1; }
footer div#cookieDisclaimer {
	position: fixed;
	width: 100%;
	height: auto;
	z-index: 99;
	bottom: 0;
	left: 0;
	background-color: #FFFFFF;
	padding: 20px;
	text-align: center;
	box-shadow: 0px -2px 10px 0px rgba(0, 0, 0, 0.15);
	-moz-box-shadow: 0px -2px 10px 0px rgba(0, 0, 0, 0.15);
	-webkit-box-shadow: 0px -2px 10px 0px rgba(0, 0, 0, 0.15);
	-ms-box-shadow: 0px -2px 10px 0px rgba(0, 0, 0, 0.15);
	-o-box-shadow: 0px -2px 10px 0px rgba(0, 0, 0, 0.15);
}
footer div#cookieDisclaimer > p {
	font-size: 16px;
	line-height: 22px;
	margin-bottom: 15px;
}
footer div#cookieDisclaimer > p > a { color: #10A0D2; }
footer div#cookieDisclaimer > button {
	color: #FFFFFF;
	background-color: #10A0D2;
	padding: 10px 15px;
	font-size: 16px;
	font-weight: 700;
	cursor: pointer;
	border-radius: 5px;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}
footer div#cookieDisclaimer > button:hover { background-color: #37AFD7; }
/* span#siteseal {
	vertical-align: middle;
	margin-left: 30px;
}
span#siteseal > img {
	max-width: 200px;
} */
div.ticker {
	background-color: #C0392B;
}
h1.tickerText {
	padding: 20px;
	font-size: 14px;
	line-height: 18px;
	text-align: center;
	color: #FFFFFF;
}


/* Responsive */
@media (min-width: 1450px) and (max-width: 2300px) {
	header.mainHeader:after { bottom: -60px; }
	section#wasMacht { margin-top: -40px; }
}
@media (min-width: 969px) and (max-width: 1121px) {
	header.mainHeader:after { bottom: -65px; }
}
@media (max-width: 1257px) {
	.col-l-3 { width: 25%; }
	.col-l-4 { width: 33.3333%; }
	.col-l-5 { width: 41.6666%; }
	footer > div > div > img {
		max-height: 30px !important;
		margin-bottom: 15px;
	}
}
@media (max-width: 1156px) {
	header.mainHeader h6 {
		font-size: 16px;
		margin-left: 25px;
	}
}
@media (max-width: 1122px) {
	header.mainHeader #mainMenu { padding-top: 36px; }
	header.mainHeader #mainMenu > li > a { font-size: 18px; }
	header.mainHeader #mainLogo, header.mainHeader #mainLogoTicket {
		max-height: 120px;
		/* margin-top: 15px; */
	}
	header.mainHeader:after { bottom: -65px; }
}
@media (max-width: 1028px) {
	.col-m-6 { width: 50%; }
	.col-m-12 { width: 100%; }
	.m-ac { text-align: center; }
}
@media (max-width: 968px) {
	header.mainHeader h6 { display: none; }
	header.mainHeader #mainLogo, header.mainHeader #mainLogoTicket {
		max-height: 115px;
		padding: 0;
		display: block;
		margin-left: 0;
	}
	header.mainHeader #mainMenu { padding-top: 23px; }
	.col-md-6 { width: 50% !important; }
	.col-md-12 { width: 100% !important; }
	.md-noPadding { padding: 0 !important; }
	main > section { flex-wrap: wrap; }
	main > section > div.img {
		order: 1;
		padding: 100px 20px;
	}
	main > section > div.info { order: 2; }
	main > section > div.info, main > section > div.img { flex: 1 0 100%; }
	div#info div.info, div#info a.info { padding: 50px 0 0 0; }
	#process, #fullContainer { height: calc(100vh - 85px); }
	header.mainHeader:after { bottom: -68px; }
}
@media (max-width: 845px) {
	#process { padding: 30px !important; }
	#process > div.step > h1 { text-align: center; }
	#fullContainer {
		height: auto;
		padding: 100px 25px;
	}
	#fullContainer > div.text {
		max-height: 525px;
		width: 100%;
		padding: 30px;
	}
	#fullContainer > div.text > p {
		font-size: 16px;
		line-height: 24px;
	}
}
@media (max-width: 813px) {
	header.mainHeader #mainMenu { padding-top: 25px; }
	header.mainHeader #mainMenu > li > a { font-size: 16px; }
	#process {
		max-height: 10000px !important;
		min-height: 520px;
		height: auto;
		padding-bottom: 150px !important;
		padding-top: 130px !important;
	}
	#process > div.step > div.options {
		padding: 0 !important;
		width: 100%;
	}
	#process > div.step > div.options > button.option, #process > div.step > div.options > a.option { width: 100%; }
	#process > div.step { position: fixed; }
	#process > div.step.visible { position: static; }
	#process > div.step > div.form > div.inputCtr {
		display: block;
		width: 100%;
	}
	#process > div.step > div.form > div.inputCtr input {
		width: 100%;
		margin-left: 0;
	}
	.rdt { display: block !important; }
	.rdtPicker {
		margin-left: 0 !important;
		width: 100% !important;
	}
	#process > div.step > div.form > div.inputCtr > span { display: none; }
	#process > div.step button.cta1 { width: 96%; }
	#process > div.step > div:not([class]) { width: 100%; }
	div.tabber { width: 100%; }
	div.tabber > ul.tabberNav > li > span { display: none; }
	div.tabber > div.tabberContent > div input {
		width: 100% !important;
		margin: 0 0 10px 0 !important;
		display: block;
	}
	div.tabber > div.tabberContent > div > div.inputRadioCtr {
		width: 49%;
		margin-right: 0;
	}
	div.tabber > div.tabberContent > div input[type="radio"], div.tabber > div.tabberContent > div input[type="checkbox"] {
		width: auto !important;
		margin-bottom: 0 !important;
	}
	div.tabber > div.tabberContent > div > span:not(.s-notAlign) {
		display: block;
		width: 100%;
		text-align: left;
		margin-left: 0 !important;
		margin-bottom: 15px;
	}
	div.tabber > div.tabberContent > div > div.inputFileCtr {
		width: 100%;
		margin-right: 0;
	}
	header.mainHeader:after { bottom: -60px; }
	#process {
		-webkit-transform: skewy(-6deg);
		transform: skewy(-6deg);
	}
	#process > div.step {
		-webkit-transform: skewy(6deg);
		transform: skewy(6deg);
	}
}
@media (max-width: 749px) {
	header.mainHeader #mainMenu { display: none; }
	header.mainHeader #mobileMenuActivator { display: block; }
	.col-s-12 { width: 100%; }
	.s-ac { text-align: center; }
	.s-padding { padding: 20px 0; }
	.s-marginTop { margin-top: 20px; }
}
@media (max-width: 570px) {
	#process > div.step > div.flightInfo > div.delayInfo {
		position: static;
		text-align: center;
		margin-top: 20px;
	}
}
@media (max-width: 500px) {
	main > section > div.info > ul.tripleList {
		display: block;
		width: 100%;
		padding-right: 0;
		text-align: center;
		margin-top: 0;
	}
	main > section > div.info > ul:first-of-type { margin-top: 30px; }
}
@media (max-width: 420px) {
	.s-display {
		display: block;
		margin: 20px auto !important;
		margin-left: auto !important;
	}
	footer ul#footerMenu li {
		display: block;
		margin: 15px 0 !important;
	}
	main > section > div.img > h2 { text-align: center; }
}


@media (min-width: 481px) and (max-width: 768px) { /* Tablets */

}
@media (max-width: 480px) { /* Phones */
	.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12 { width: 100%; }
	div.tabber > div.tabberContent > div button {
		width: 100%;
		display: block;
		margin-top: 20px;
	}
}

/* Whatsapp */
.styles-module_whatsappButton__tVits {
	bottom: 5rem !important;
	right: 1rem !important;
	background-color: #FFFFFF !important;
}
.styles-module_whatsappButton__tVits > svg { fill: #10A0D2 !important; }
.styles-module_chatBody__VBxQH { background-image: none !important; }
.styles-module_chatHeader__gaeOA { background-color: #10A0D2 !important; }
