.rdt {
    position: relative;
    display: inline-block;
}
.rdtPicker {
    display: none;
    position: absolute;
    width: 250px;
    padding: 4px;
    margin-top: -4px;
    margin-left: 10px;
    z-index: 99999 !important;
    background: #FFFFFF;
    /*box-shadow: 0 1px 3px 0px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 1px 3px 0px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 1px 3px 0px rgba(0, 0, 0, 0.3);
    -ms-box-shadow: 0 1px 3px 0px rgba(0, 0, 0, 0.3);
    -o-box-shadow: 0 1px 3px 0px rgba(0, 0, 0, 0.3);*/
    border-radius: 5px;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    border: 3px solid #10A0D2;
    color: #333333;
}
.rdtOpen .rdtPicker { display: block; }
.rdtStatic .rdtPicker {
    box-shadow: none;
    position: static;
}
.rdtPicker .rdtTimeToggle { text-align: center; }
.rdtPicker table {
    width: 100%;
    margin: 0;
}
.rdtPicker td, .rdtPicker th {
    text-align: center;
    height: 28px;
    padding-top: 5px;
}
.rdtPicker td { cursor: pointer; }
.rdtPicker td.rdtDay:hover, .rdtPicker td.rdtHour:hover, .rdtPicker td.rdtMinute:hover, .rdtPicker td.rdtSecond:hover, .rdtPicker .rdtTimeToggle:hover {
    background: #EEEEEE;
    cursor: pointer;
}
.rdtPicker td.rdtOld, .rdtPicker td.rdtNew { color: #999999; }
.rdtPicker td.rdtToday { position: relative; }
.rdtPicker td.rdtToday:before {
    content: '';
    display: inline-block;
    border-left: 7px solid transparent;
    border-bottom: 7px solid #10A0D2;
    border-top-color: rgba(0, 0, 0, 0.2);
    position: absolute;
    bottom: 4px;
    right: 4px;
}
.rdtPicker td.rdtActive, .rdtPicker td.rdtActive:hover {
    background-color: #10A0D2;
    color: #FFFFFF;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}
.rdtPicker td.rdtActive.rdtToday:before { border-bottom-color: #FFFFFF; }
.rdtPicker td.rdtDisabled, .rdtPicker td.rdtDisabled:hover {
    background: none;
    color: #999999;
    cursor: not-allowed;
}
.rdtPicker td span.rdtOld { color: #999999; }
.rdtPicker td span.rdtDisabled, .rdtPicker td span.rdtDisabled:hover {
    background: none;
    color: #999999;
    cursor: not-allowed;
}
.rdtPicker th { border-bottom: 1px solid #f9f9f9; }
.rdtPicker .dow {
    width: 14.2857%;
    border-bottom: none;
    cursor: default;
}
.rdtPicker th.rdtSwitch {
    width: 100px;
    padding-top: 10px;
}
.rdtPicker th.rdtNext, .rdtPicker th.rdtPrev {
    font-size: 21px;
    vertical-align: top;
    padding-bottom: 10px;
}
.rdtPrev span, .rdtNext span {
    display: block;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.rdtPicker th.rdtDisabled, .rdtPicker th.rdtDisabled:hover {
    background: none;
    color: #999999;
    cursor: not-allowed;
}
.rdtPicker thead tr:first-child th { cursor: pointer; }
.rdtPicker thead tr:first-child th:hover { background: #EEEEEE; }
.rdtPicker tfoot { border-top: 1px solid #f9f9f9; }
.rdtPicker button {
    border: none;
    background: none;
    cursor: pointer;
}
.rdtPicker button:hover { background-color: #EEEEEE; }
.rdtPicker thead button {
    width: 100%;
    height: 100%;
}
td.rdtMonth, td.rdtYear {
    height: 50px;
    width: 25%;
    cursor: pointer;
}
td.rdtMonth:hover, td.rdtYear:hover { background: #EEEEEE; }
.rdtCounters { display: inline-block; }
.rdtCounters > div { float: left; }
.rdtCounter { height: 100px; }
.rdtCounter { width: 40px; }
.rdtCounterSeparator { line-height: 100px; }
.rdtCounter .rdtBtn {
    height: 40%;
    line-height: 40px;
    cursor: pointer;
    display: block;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.rdtCounter .rdtBtn:hover { background: #EEEEEE; }
.rdtCounter .rdtCount {
    height: 20%;
    font-size: 1.2em;
}
.rdtMilli {
    vertical-align: middle;
    padding-left: 8px;
    width: 48px;
}
.rdtMilli input {
    width: 100%;
    font-size: 1.2em;
    margin-top: 37px;
}
.rdtTime td { cursor: default; }